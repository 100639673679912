export default {
  'profile': {
    icon: 'material-symbols:person-edit-outline-rounded',
    link: '/my-hos/profile',
  },
  'shop-rent': {
    icon: 'material-symbols:shopping-cart-outline-rounded',
    link: '/shop/rent',
  },
  'shop-buy': {
    icon: 'material-symbols:shopping-cart-outline-rounded',
    link: '/shop/buy',
  },
  'foreign-machinery': {
    icon: 'material-symbols:location-away-outline-rounded',
    link: '/my-hos/foreign-machinery',
  },
  'rentals': {
    icon: 'material-symbols:calendar-clock-outline',
    link: '/my-hos/rental',
  },
  'sales': {
    icon: 'material-symbols:shopping-cart-outline',
    link: '/my-hos/sale',
  },
  'service-project': {
    icon: 'material-symbols:design-services-outline-rounded',
    link: '/my-hos/service-project',
  },
  'special': {
    icon: 'material-symbols:award-star-outline-rounded',
    link: '/my-hos/special',
  },
  'machinery': {
    icon: 'material-symbols:forklift-outline-rounded',
    link: '/my-hos/machinery',
  },
}
